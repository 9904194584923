import React, { useContext, useState } from "react"
import Image from "next/legacy/image"

import styles from './ListItemTicket.module.scss'

import { CartContext } from '../../../contexts/CartContext'

import { CartQuantityPicker } from '../../Cart/CartQuantityPicker/CartQuantityPicker'
import { ProductType } from '../../../types/ProductType'
import { AddToCartTicketType } from '../../../types/CartType'
import { TicketPopup } from './TicketPopup/TicketPopup'
import { BookingSiteOperated } from "../../Misc/BookingSiteOperated/BookingSiteOperated";

import { YearContext } from "../../../contexts/YearContext"
import { YearPicker } from '../YearPicker/YearPicker'

type ListItemTicketProps = {
  product: ProductType,
}

export function ListItemTicket({ product }: ListItemTicketProps ) {

  const [adultQty, setAdultQty] = useState(0)
  const [childQty, setChildQty] = useState(0)

  const aboveText = "Park-to-Park"

  const { year } = useContext(YearContext)

  const adultTicketId = product.seasons.find(s => s.title === year)?.tickets.find(t => t.type === "Adult")?.ticket_id
  const childTicketId = product.seasons.find(s => s.title === year)?.tickets.find(t => t.type === "Child")?.ticket_id

  const adultTicket = product.tickets.find(t => t.ticket_id === adultTicketId)
  const childTicket = product.tickets.find(t => t.ticket_id === childTicketId)

  const adultPrice = product.seasons.find(s => s.title === year)?.tickets.find(t => t.ticket_id === adultTicket?.ticket_id)?.price ?? ''
  const childPrice = product.seasons.find(s => s.title === year)?.tickets.find(t => t.ticket_id === childTicket?.ticket_id)?.price ?? ''

  const availableYears = Array.from(new Set(product.seasons.filter(s => s.title.match(/^20[0-9]{2}$/)).map(s => s.title)))
  const yearPicker = <YearPicker name={ 'year-' + product.id } years={availableYears} />
  const yearPickerPopup = <YearPicker name={ 'popup-year-' + product.id } years={availableYears} />

  // @todo: Where should the logic for this go? Does this belong in the API?
  type LogoImg = { path: string, alt: string }
const logos: Array<LogoImg> = [
    {
      path: '/park-logos/universal-studios.png',
      alt: 'Universal Studios'
    },
    {
      path: '/park-logos/islands-of-adventure.png',
      alt: "Islands of Adventure"
    }
  ]
  if (product.title.includes('3 Park')) {
    logos.push({
      path: '/park-logos/volcano-bay.png',
      alt: 'Volcano Bay'
    })
  }

  return (
    <CartContext.Consumer>
      {({cartAPI, cart}) => {

        const addToBasketBtn = (callback?: Function) => (
          <button
            className={styles.btn}
            disabled={ ( adultQty < 1 && childQty < 1 ) || cart?.loading }
            onClick={async () => {
              const items: Array<AddToCartTicketType> = []
              if (adultTicket) items.push({ ticketId: adultTicket.ticket_id, qty: adultQty })
              if (childTicket) items.push({ ticketId: childTicket.ticket_id, qty: childQty })
              if (items.length) await cartAPI?.add(items)
              if (callback) callback()
              setAdultQty(0)
              setChildQty(0)
            }}
          >ADD TO BASKET</button>
        )

        const adultQtyPicker = adultTicket ? <CartQuantityPicker ticketPrice={ adultPrice } showTicketPrice={true} showTotalPrice={false} qty={adultQty} setQty={(qty: number) => setAdultQty(qty)} loading={cart?.loading ?? false} leftText={ adultTicket.type + ' ticket:' } leftSubText={ adultTicket.type_description } numDays={ product.num_days } /> : null
        const childQtyPicker = childTicket ? <CartQuantityPicker ticketPrice={childPrice} showTicketPrice={true} showTotalPrice={false} qty={childQty} setQty={(qty: number) => setChildQty(qty)} loading={cart?.loading ?? false} leftText={childTicket.type + ' ticket:'} leftSubText={childTicket.type_description} numDays={product.num_days} /> : null

        return (
          <div className={ `${styles.listItemTicket} ${ product.internal.persuasion_ribbon ? styles.hasPersuasion : null }`}>
            <div className={styles.ticketProductFullWidth}>
              { product.internal.persuasion_ribbon ? <div className={ styles.persuasionRibbon }>{ product.internal.persuasion_ribbon }</div> : null }
              <p className={ styles.textAbove }>{ aboveText }</p>
              <h3>{ product.seo_title }</h3>
              <div className={ styles.row }>
                <div className={ styles.logosHolder }>
                  <div className={ styles.logos }>
                    {
                      logos.map((logo, idx) => {
                        return (
                          <span key={logo.path}>
                            {idx > 0 ? <Image src={'/park-logos/plus.png'} width={27} height={60} alt='plus-logo' /> : null}
                            <Image priority src={logo.path} width={83} height={60} alt={logo.alt} />
                          </span>
                        )
                      })
                    }
                  </div>
                  { product.internal.persuasion_ribbon ? <p className={ styles.persuasionText }>{ product.internal.persuasion_message }</p> : null }
                </div>
                <div className={ styles.qtyPickers }>
                  {/* year picker will be commented back in when all tickets are dated in 2025 */}
                  {/* { yearPicker } */}
                  { adultQtyPicker }
                  { childQtyPicker }
                </div>
            </div>
          </div>
          <div className={ styles.footer }>
            <div className={styles.footerDetails}>
              <TicketPopup product={product} adultQtyPicker={adultQtyPicker} childQtyPicker={childQtyPicker} yearPicker={yearPickerPopup} addToBasketBtn={addToBasketBtn}><p className={styles.detailsLink}><a href="#na" className={styles.modalLink}>View Details</a></p></TicketPopup>
              <div className={styles.button}>{ addToBasketBtn() }</div>
            </div>
            <BookingSiteOperated placement="ticket" />
          </div>
        </div>
        )
      }}
    </CartContext.Consumer>
  )
}
