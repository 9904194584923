import { GetServerSideProps } from 'next'

import { List } from '../../components/List/List'
import { ListItemTicket } from '../../components/List/ListItemTicket/ListItemTicket'
import { ListItemCalendarTicket } from '../../components/List/ListItemCalendarTicket/ListItemCalendarTicket'
import { BestPriceGuarantee } from '../../components/Banners/BestPriceGuarantee/BestPriceGuarantee'
import { FloatingCart } from '../../components/Cart/FloatingCart/FloatingCart'
import { ContinueLink } from '../../components/Misc/ContinueLink/ContinueLink'
import { TextApiResponseType, TextType } from '../../types/TextType'
import { PageView } from '../../components/Analytics/PageView/PageView'

import type { ProductType, ProductLeadPriceType, ProductLeadPriceDisplayType } from '../../types/ProductType'
import { useContext, useEffect } from 'react';
import { CartContext } from '../../contexts/CartContext';
import { YearContext } from '../../contexts/YearContext';
import Head from 'next/head';
import { YearPicker } from '../../components/List/YearPicker/YearPicker'
import { getLeadPricing } from '../../utils/pricingUtils'

// last 2 ids are epic tickets
const ticketIds = [234481, 328366, 234771, 333439, 336334] // 2 Park and 3 Park tickets
const textTypes = ['ticket_page_intro']

function ParkTickets({ products, texts, productLeadPricing }: { products: Array<ProductType>, texts: Array<TextType>, productLeadPricing:Array<ProductLeadPriceType> }) {
  const { year, setYear } = useContext(YearContext)

  const title = "Tickets"
  const introText = texts.find(t => t.text_type === 'ticket_page_intro')?.body ?? ''

  const { cart } = useContext(CartContext)
  useEffect(()=>{
    cart.data.tickets.map(ticket => {
      if (ticket.tags.includes('rooms')) {
        setYear && setYear(ticket.date.split('-').slice(0,1).join())
      }
    })
  },[cart,setYear])

  const leadPricingData = getLeadPricing(productLeadPricing)

  let availableYears: (string | string[] | undefined)[] = products.map((product, index) => {
    // check if product has values in last and first available months
    if (product.calendar_first_available_month.substring(0, 4) !== '' && product.calendar_last_available_month.substring(0, 4) !== '') {
      return [product.calendar_first_available_month.substring(0, 4), product.calendar_last_available_month.substring(0, 4)];
    }
  })

  availableYears = availableYears.flat()
  availableYears = availableYears.filter((item, index) => {
    return (availableYears.indexOf(item) === index && item !== undefined);
  })

  const yearPicker = <YearPicker name={ 'year-' + products[0].id } years={availableYears} />

  return (
    <>
      <Head key={'park-tickets'}><title>Universal Orlando™ | Park Tickets</title></Head>
      <PageView
        pageName='Park Tickets'
        productsOnPage={ products }
      />
      <main className='products'>
        <FloatingCart showNextStep={true} currentSection='tickets' placement="top" />
        <ContinueLink currentSection='tickets' />
        <List title={title} introText={introText} rating={''}>
        {yearPicker}
          {
            products.map((product: ProductType) => {
              const firstAvailYear = product.calendar_first_available_month.substring(0, 4);
              const lastAvailYear = product.calendar_last_available_month.substring(0, 4);

              // Find the lead price using the product id.
              const leadPriceDisplay: ProductLeadPriceDisplayType | undefined = leadPricingData.find(productLeadPrice => productLeadPrice.id == product.id)

              // Check the product matches the selected year
              // or has availability that year (case: the ticket/nid is available longer than a year)
              if (year && (firstAvailYear === year || ( year > firstAvailYear && year <= lastAvailYear))) {
                if (product.calendar_based_pricing === 1) {
                  // Calendar based tickets
                  return (
                    <ListItemCalendarTicket
                      key={product.id}
                      product={product}
                      isDatedTicket={true}
                      leadPrice={leadPriceDisplay}
                    />
                  )
                } else {
                  // Non-dated tickets
                  return (
                    <ListItemTicket
                      key={product.id}
                      product={product}
                    />
                  )
                }
              }
            })
          }
        </List>
        <FloatingCart showNextStep={true} currentSection='tickets' placement="bottom" />
      </main>
      <BestPriceGuarantee />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {

  // Get products:
  const fetches = ticketIds.map(ticketId => fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/products/${ticketId}?view=website`))
  const results = await Promise.all(fetches)

  const products: Array<ProductType | null> = await Promise.all(results.map(result => {

    if (result.statusText === 'OK') {
      return result.json()
    } else {
      return null
    }
  }))

  // Get Lead Prices:
  const leadPriceFetch = ticketIds.map(ticketId => fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/products/lead-price/${ticketId}`))
  const leadPriceResult = await Promise.all(leadPriceFetch);
  // Add each lead price result to Park Ticket props.
  const leadPrice: Array<ProductLeadPriceType | null> = await Promise.all(leadPriceResult.map(result => {
    if (result.statusText === 'OK') {
      return result.json()
    } else {
      return null
    }
  }))

  // Get page text:
  const textResult = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/text?types=${textTypes.join(",")}`)
  const texts: TextApiResponseType = await textResult.json()

  return { props: {
    products: products.filter(p => p !== null),
    texts: texts.data,
    productLeadPricing: leadPrice.filter(l => l !== null)
  }}


}

export default ParkTickets

